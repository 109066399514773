@font-face {
  font-family: "akkurat-light";
  src: url('fonts/AkkuratLLWeb-Light.woff2') format('woff2');
  src: url('fonts/AkkuratLLWeb-Light.woff') format('woff'); }

  @font-face {
    font-family: "akkurat-bold";
    src: url('fonts/AkkuratLLWeb-Bold.woff2') format('woff2');
    src: url('fonts/AkkuratLLWeb-Bold.woff') format('woff'); }


$break-small: 800px;

:root {
  --opacity: .15;
}


html,body{
    background-color: rgb(15, 15, 15);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}




// Spotlight

.spotlight {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    // background: radial-gradient(circle at var(--x, 50%) var(--y, 50%), 
    //         rgba(255, 255, 255, 0%) 10%, 
    //         rgba(0,0,0,1) 35%);

    animation: 4s ease-in-out 2000ms 1 fadeIn2-desktop;

    @media screen and (max-width: $break-small){
      animation: 4s ease-in-out 2000ms 1 fadeIn2-mobile;
    }

    $divider: 2.5;

    background: radial-gradient(circle at var(--x, 50%) var(--y, 35%), 
            hsl(0, 0%, 100%) 0%,
            hsl(0, 0%, 99.36%) calc(8.1% / #{$divider}),
            hsl(0, 0%, 97.54%) calc(15.5% / #{$divider}),
            hsl(0, 0%, 94.66%) calc(22.5% / #{$divider}),
            hsl(0, 0%, 90.81%) calc(29% / #{$divider}),
            hsl(0, 0%, 86.07%) calc(35.3% / #{$divider}),
            hsl(0, 0%, 80.5%) calc(41.2% / #{$divider}),
            hsl(0, 0%, 74.16%) calc(47.1% / #{$divider}),
            hsl(0, 0%, 67.09%) calc(52.9% / #{$divider}),
            hsl(0, 0%, 59.33%) calc(58.8% / #{$divider}),
            hsl(0, 0%, 50.92%) calc(64.7% / #{$divider}),
            hsl(0, 0%, 41.88%) calc(71% / #{$divider}),
            hsl(0, 0%, 32.25%) calc(77.5% / #{$divider}),
            hsl(0, 0%, 22.04%) calc(84.5% / #{$divider}),
            hsl(0, 0%, 11.29%) calc(91.9% / #{$divider}),
            hsl(0, 0%, 0%) calc(100% / #{$divider}));
    mix-blend-mode: var(--blend, multiply);
} 

.scene {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: url(images/burnt-logo-2.jpg) center center;
    background-size: cover;
    opacity: var(--opacity);
    animation: 5s ease-in 0ms 1 fadeIn-background;
    overflow: hidden;

    @media screen and (max-width: $break-small){
      opacity: var(--opacity);
      animation: 5s ease-in 0ms 1 fadeIn-background-mobile;
    }

    
}

@keyframes fadeIn-background {
    0% {
      opacity: 0;
    }
    100% {
      opacity: var(--opacity);
    }
}

@keyframes fadeIn-background-mobile {
  0% {
    opacity: 0;
  }
  100% {
    opacity: var(--opacity);
  }
}

@keyframes fadeIn2-desktop {
  0% {
    scale: 1;
  }
  50% {
    scale: 2;
  }
  100% {
    scale: 1;
  }
}

@keyframes fadeIn2-mobile {
  0% {
    background-size: 100% 150%;
  }
  100% {
    background-size: 100% 100%;
  }
}





// VIDEO BACKGROUND


.background{
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  opacity: .8;
  animation: 5s ease-in 0ms 1 fadeIn-video;
}

.video-wrap {
  position: absolute;
  inset: 0;
  pointer-events: none;
  overflow: hidden;
  height: 100%;
}


@keyframes fadeIn-video {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}












// NAV

$unit: 35px;
$unit-small: 20px;

nav{
  box-sizing: border-box;
  position: relative;
  z-index: 10;

  padding: $unit;

  color: white;
  text-transform: uppercase;

  font-family: "akkurat-light";
  font-size: 15px;

  @media screen and (max-width: $break-small){
    display: flex;
    flex-direction: column;
    padding: $unit-small;
    font-size: 13px;
  }
}

.logo-type{
  font-family: "akkurat-bold";

  margin-bottom: 10px;
}

.info-wrapper{
  display: flex;
  position: relative;
  justify-content: space-between ;

  @media screen and (max-width: $break-small){
    flex-direction: column;
    flex-grow: 1;
  }

  .info-left{
    display: flex;

    div{
      margin-right: $unit;

      @media screen and (max-width: $break-small){
        margin-right: $unit-small;
      }
    }
  }

  .info-right{
    display: flex;

    @media screen and (max-width: $break-small){
      display: block;
    }
  }
}




.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu,
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu {
}

.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_helptext,
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_helptext {

}

.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_gdpr_text,
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_gdpr_text {

}

.klaviyo_styling .klaviyo_field_wrap {
  display: flex;
  position: relative;
}

.klaviyo_styling .klaviyo_field_group .klaviyo_form_actions {
  text-align: left;
}

.klaviyo_styling .klaviyo_messages .error_message,
.klaviyo_styling .klaviyo_messages .success_message {
  display: block;
  font-family: "akkurat-bold";
  text-align: right;
  position: absolute;
  top: -26px;
  right: 0;

  @media screen and (max-width: $break-small){
    text-align: left;
    right: auto;
    left: 0;
    width: 90vw; 
  }
}

.klaviyo_styling .klaviyo_messages .success_message {

  @media screen and (max-width: $break-small){
    top: -45px; 
  }
}



.klaviyo_styling .klaviyo_messages .success_message .klaviyo_header {
  padding-bottom: 10px;
}

.klaviyo_styling .klaviyo_messages .success_message .klaviyo_subheader {
  font-family: 'akkurat-light';
}

.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu input[type=checkbox]+label,
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu input[type=checkbox]+label {

}

.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu input[type=text],
.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu input[type=email],
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu input[type=text],
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu input[type=email] {
  border-radius: 2px;
  
  background: transparent;
  border: 1px solid transparent;
  // border-bottom: 1px solid white;
  padding: 5px 5px;
  margin-right: 10px;
  width: 12vw;
  text-align: right;

  color: white;

  transition: all 500ms ease;

  &:focus{
    outline: none;
    width: 20vw;
    // border: 1px solid white;
    background-color: hsla(0, 0%, 0%, 30%);

    @media screen and (max-width: $break-small){
      width: 52vw;
    }
  }

  @media screen and (max-width: $break-small){
    padding: 10px;
    width: 20vw;
    text-align: left;
  }
}

.klaviyo_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_submit_button,
.klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_submit_button {
  border-radius: 2px;

  background: transparent;
  // border: 1px solid white;
  border: none;
  color: white;
  padding: 5px 2px;

  @media screen and (max-width: $break-small){
    padding: 10px 20px;
  }

  transition: all 500ms ease;

  &:hover{
    padding: 5px 15px;
    background-color: white;
    color: black;

    @media screen and (max-width: $break-small){
      padding: 10px 20px;
    }
  }
}

// .klaviyo_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_submit_button:hover,
// .klaviyo_condensed_styling.klaviyo_gdpr_embed_UTpjXu .klaviyo_submit_button:hover {
//   background-color: white;
//   color: black;
// }
